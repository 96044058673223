import { admin2 } from './instance'

// 取得指定訂閱設定
export const FindSubscribeConfig = async ({
  shopId,
}) => {
  return await admin2({
    method: 'get',
    url: `/${shopId}/subscriptionConfig`,
  })
}
// 新增訂閱設定
export const CreateSubscribeConfig = async ({
  shopId,
  subscriptionApiKey,
  subscriptionApiOrgId,
}) => {
  return await admin2({
    method: 'put',
    url: `/${shopId}/subscriptionConfig`,
    data: {
      subscriptionApiKey,
      subscriptionApiOrgId,
    },
  })
}

// 新增訂閱合約
export const CreateSubscribeContract = async ({
  shopId,
  name,
  content,
}) => {
  return await admin2({
    method: 'post',
    url: `/${shopId}/subscriptionContract`,
    data: {
      name,
      content,
    },
  })
}
// 取得訂閱合約
export const GetSubscribeContract = async ({
  shopId,
  name,
  hasPlan,
  start,
  limit,
  sortBy,
  sortType,
}) => {
  return await admin2({
    method: 'get',
    url: `/${shopId}/subscriptionContract`,
    params: {
      name,
      hasPlan,
      start,
      limit,
      sortBy,
      sortType,
    },
  })
}
// 取得訂閱合約數量
export const GetSubscribeContractCount = async ({
  shopId,
  name,
  hasPlan,
}) => {
  return await admin2({
    method: 'get',
    url: `/${shopId}/subscriptionContract/count`,
    params: {
      name,
      hasPlan,
    },
  })
}
// 取得指定訂閱合約
export const FindSubscribeContract = async ({
  shopId,
  id,
}) => {
  return await admin2({
    method: 'get',
    url: `/${shopId}/subscriptionContract/${id}`,
  })
}
// 更新訂閱合約
export const UpdateSubscribeContract = async ({
  shopId,
  id,
  name,
  content,
}) => {
  return await admin2({
    method: 'put',
    url: `/${shopId}/subscriptionContract/${id}`,
    data: {
      name,
      content,
    },
  })
}
// 刪除訂閱合約
export const DeleteSubscribeContract = async ({
  shopId,
  id,
}) => {
  return await admin2({
    method: 'delete',
    url: `/${shopId}/subscriptionContract/${id}`,
  })
}

// 新增品項組合
export const CreateSubscribeEquityPack = async ({
  shopId,
  name,
  equities,
}) => {
  return await admin2({
    method: 'post',
    url: `/${shopId}/subscriptionEquityPack`,
    data: {
      name,
      equities,
    },
  })
}
// 取得品項組合
export const GetSubscribeEquityPack = async ({
  shopId,
  start,
  limit,
  sortBy,
  sortType,
  name,
  equityName,
  hasPlan,
}) => {
  return await admin2({
    method: 'get',
    url: `/${shopId}/subscriptionEquityPack`,
    params: {
      start,
      limit,
      sortBy,
      sortType,
      name,
      equityName,
      hasPlan,
    },
  })
}
// 取得品項組合數量
export const GetSubscribeEquityPackCount = async ({
  shopId,
  name,
  equityName,
  hasPlan,
}) => {
  return await admin2({
    method: 'get',
    url: `/${shopId}/subscriptionEquityPack/count`,
    params: {
      name,
      equityName,
      hasPlan,
    },
  })
}
// 取得指定品項組合
export const FindSubscribeEquityPack = async ({
  shopId,
  id,
}) => {
  return await admin2({
    method: 'get',
    url: `/${shopId}/subscriptionEquityPack/${id}`,
  })
}
// 更新品項組合
export const UpdateSubscribeEquityPack = async ({
  shopId,
  id,
  name,
  equities,
}) => {
  return await admin2({
    method: 'put',
    url: `/${shopId}/subscriptionEquityPack/${id}`,
    data: {
      name,
      equities,
    },
  })
}
// 刪除品項組合
export const DeleteSubscribeEquityPack = async ({
  shopId,
  id,
}) => {
  return await admin2({
    method: 'delete',
    url: `/${shopId}/subscriptionEquityPack/${id}`,
  })
}

// 新增訂閱方案
export const CreateSubscribePlan = async ({
  shopId,
  name,
  imageId,
  pleaseNote,
  renewPlanId,
  isAutoRenew,
  isAllowMemberCancelAutoRenew,
  equityOptions,
  periodSettings,
  installmentSettings,
  paymentTimeSettings,
  contract,
}) => {
  return await admin2({
    method: 'post',
    url: `/${shopId}/subscriptionPlan`,
    data: {
      name,
      imageId,
      pleaseNote,
      renewPlanId,
      isAutoRenew,
      isAllowMemberCancelAutoRenew,
      equityOptions,
      periodSettings,
      installmentSettings,
      paymentTimeSettings,
      contract,
    },
  })
}
// 取得訂閱方案
export const GetSubscribePlan = async ({
  shopId,
  start,
  limit,
  sortBy,
  sortType,
  name,
  equityPackName,
  isAutoRenew,
  hasContract,
}) => {
  return await admin2({
    method: 'get',
    url: `/${shopId}/subscriptionPlan`,
    params: {
      start,
      limit,
      sortBy,
      sortType,
      name,
      equityPackName,
      isAutoRenew,
      hasContract,
    },
  })
}
// 取得訂閱方案數量
export const GetSubscribePlanCount = async ({
  shopId,
  name,
  equityPackName,
  isAutoRenew,
  hasContract,
}) => {
  return await admin2({
    method: 'get',
    url: `/${shopId}/subscriptionPlan/count`,
    params: {
      name,
      equityPackName,
      isAutoRenew,
      hasContract,
    },
  })
}
// 取得指定訂閱方案
export const FindSubscribePlan = async ({
  shopId,
  id,
}) => {
  return await admin2({
    method: 'get',
    url: `/${shopId}/subscriptionPlan/${id}`,
  })
}
// 更新訂閱方案
export const UpdateSubscribePlan = async ({
  shopId,
  id,
  name,
  imageId,
  pleaseNote,
  isAutoRenew,
  isAllowMemberCancelAutoRenew,
  equityOptions,
  periodSettings,
  installmentSettings,
  paymentTimeSettings,
  contract,
}) => {
  return await admin2({
    method: 'put',
    url: `/${shopId}/subscriptionPlan/${id}`,
    data: {
      name,
      imageId,
      pleaseNote,
      isAutoRenew,
      isAllowMemberCancelAutoRenew,
      equityOptions,
      periodSettings,
      installmentSettings,
      paymentTimeSettings,
      contract,
    },
  })
}
// 刪除訂閱方案
export const DeleteSubscribePlan = async ({
  shopId,
  id,
}) => {
  return await admin2({
    method: 'delete',
    url: `/${shopId}/subscriptionPlan/${id}`,
  })
}

// 取得訂閱訂單
export const GetSubscribeOrder = async ({
  shopId,
  start,
  limit,
  sortBy,
  sortType,
  status,
  hasFailRecord,
  customizedStage,
  planName,
  equityPackName,
  memberPhone,
  memberName,
  startDate,
  endDate,
}) => {
  return await admin2({
    method: 'get',
    url: `/${shopId}/subscriptionOrder`,
    params: {
      start,
      limit,
      sortBy,
      sortType,
      status,
      hasFailRecord,
      customizedStage,
      planName,
      equityPackName,
      memberPhone,
      memberName,
      startDate,
      endDate,
    },
  })
}
// 取得訂閱訂單數量
export const GetSubscribeOrderCount = async ({
  shopId,
  status,
  hasFailRecord,
  customizedStage,
  planName,
  equityPackName,
  memberPhone,
  memberName,
  startDate,
  endDate,
}) => {
  return await admin2({
    method: 'get',
    url: `/${shopId}/subscriptionOrder/count`,
    params: {
      status,
      hasFailRecord,
      customizedStage,
      planName,
      equityPackName,
      memberPhone,
      memberName,
      startDate,
      endDate,
    },
  })
}
// 取得指定訂閱訂單
export const FindSubscribeOrder = async ({
  shopId,
  id,
}) => {
  return await admin2({
    method: 'get',
    url: `/${shopId}/subscriptionOrder/${id}`,
  })
}
// 取消訂閱
export const CancelSuscribeOrder = async ({
  shopId,
  id,
  orderPaymentId,
}) => {
  return await admin2({
    method: 'put',
    url: `/${shopId}/subscriptionOrder/${id}/cancel`,
    data: {
      orderPaymentId,
    },
  })
}
// 暫停訂閱
export const PauseSuscribeOrder = async ({
  shopId,
  id,
  pauseOrderPaymentId,
  resumeOrderPaymentId,
}) => {
  return await admin2({
    method: 'put',
    url: `/${shopId}/subscriptionOrder/${id}/pause`,
    data: {
      pauseOrderPaymentId,
      resumeOrderPaymentId,
    },
  })
}

// 取得訂閱訂單付款紀錄
export const GetSubscribeOrderPayment = async ({
  shopId,
  orderId,
  start,
  limit,
  sortBy,
  sortType,
  status,
  customizedStage,
  hideOldRecord,
}) => {
  return await admin2({
    method: 'get',
    url: `/${shopId}/order/${orderId}/subscriptionOrderPayment`,
    params: {
      start,
      limit,
      sortBy,
      sortType,
      status,
      customizedStage,
      hideOldRecord,
    },
  })
}
// 取得訂閱訂單付款紀錄數量
export const GetSubscribeOrderPaymentCount = async ({
  shopId,
  orderId,
  status,
  customizedStage,
  hideOldRecord,
}) => {
  return await admin2({
    method: 'get',
    url: `/${shopId}/order/${orderId}/subscriptionOrderPayment/count`,
    params: {
      status,
      customizedStage,
      hideOldRecord,
    },
  })
}
// 取得指定訂閱訂單付款紀錄
export const FindSubscribeOrderPayment = async ({
  shopId,
  orderId,
  id,
}) => {
  return await admin2({
    method: 'get',
    url: `/${shopId}/order/${orderId}/subscriptionOrderPayment/${id}`,
  })
}
// 更新訂閱訂單付款紀錄
export const UpdateSubscribeOrderPayment = async ({
  shopId,
  orderId,
  id,
  name,
  status,
  paymentDate,
  paymentAmount,
  paymentType,
  refundAmount,
  refundDate,
  refundType,
  customizedStage,
  note,
}) => {
  return await admin2({
    method: 'put',
    url: `/${shopId}/order/${orderId}/subscriptionOrderPayment/${id}`,
    params: {
      name,
    },
    data: {
      status,
      paymentDate,
      paymentAmount,
      paymentType,
      refundAmount,
      refundDate,
      refundType,
      customizedStage,
      note,
    },
  })
}

// 會員商城取得訂閱方案商品
export const GetMemberStoreSubscribeProduct = async ({
  shopId,
  start,
  limit,
  name,
}) => {
  return await admin2({
    method: 'get',
    url: `/${shopId}/memberStoreSubscriptionProduct`,
    params: {
      start,
      limit,
      name,
    },
  })
}
// 會員商城取得訂閱方案商品數量
export const GetMemberStoreSubscribeProductCount = async ({
  shopId,
  name,
}) => {
  return await admin2({
    method: 'get',
    url: `/${shopId}/memberStoreSubscriptionProduct/count`,
    params: {
      name,
    },
  })
}
// 新增會員商城訂閱方案商品
export const CreateMemberStoreSubscribeProduct = async ({
  shopId,
  planId,
  isPublic,
  order,
}) => {
  return await admin2({
    method: 'post',
    url: `/${shopId}/memberStoreSubscriptionProduct`,
    data: {
      planId,
      isPublic,
      order,
    },
  })
}
// 取得指定會員商城訂閱方案商品
export const FindMemberStoreSubscribeProduct = async ({
  shopId,
  id,
}) => {
  return await admin2({
    method: 'get',
    url: `/${shopId}/memberStoreSubscriptionProduct/${id}`,
  })
}
// 更新會員商城訂閱方案商品
export const UpdateMemberStoreSubscribeProduct = async ({
  shopId,
  id,
  planId,
  isPublic,
  order,
}) => {
  return await admin2({
    method: 'put',
    url: `/${shopId}/memberStoreSubscriptionProduct/${id}`,
    data: {
      planId,
      isPublic,
      order,
    },
  })
}
// 刪除會員商城訂閱方案商品
export const DeleteMemberStoreSubscribeProduct = async ({
  shopId,
  id,
}) => {
  return await admin2({
    method: 'delete',
    url: `/${shopId}/memberStoreSubscriptionProduct/${id}`,
  })
}

// 預覽訂閱合約訂閱方案
export const PreviewContract = async ({
  shopId,
  id,
}) => {
  return await admin2({
    method: 'get',
    url: `/${shopId}/subscriptionContract/${id}/previewPlan`,
  })
}

// 預覽品項組合訂閱方案
export const PreviewEquityPack = async ({
  shopId,
  id,
}) => {
  return await admin2({
    method: 'get',
    url: `/${shopId}/subscriptionEquityPack/${id}/previewPlan`,
  })
}

// 取得訂單付款紀錄發票
export const GetSubscriptionPaymentInvoiceRecord = async ({
  shopId,
  orderId,
  id,
}) => {
  return await admin2({
    method: 'get',
    url: `/${shopId}/order/${orderId}/subscriptionOrderPayment/${id}/getInvoiceRecord`,
  })
}
// 更新訂單付款紀錄發票
export const UpsertSubscriptionPaymentInvoiceRecord = async ({
  shopId,
  orderId,
  id,
  status,
  buyerType,
  buyerName,
  buyerIdentifier,
  email,
  amount,
  note,
  invoiceNo,
}) => {
  return await admin2({
    method: 'put',
    url: `/${shopId}/order/${orderId}/subscriptionOrderPayment/${id}/upsertInvoiceRecord`,
    data: {
      status,
      buyerType,
      buyerName,
      buyerIdentifier,
      email,
      amount,
      note,
      invoiceNo,
    },
  })
}
// 手動開立發票
export const ManualIssueInvoice = async ({
  shopId,
  orderId,
  id,
  buyerType,
  buyerName,
  buyerIdentifier,
  email,
  amount,
  note,
}) => {
  return await admin2({
    method: 'post',
    url: `/${shopId}/order/${orderId}/subscriptionOrderPayment/${id}/manualIssueInvoice`,
    data: {
      buyerType,
      buyerName,
      buyerIdentifier,
      email,
      amount,
      note,
    },
  })
}

// 更新訂閱紀錄發票資訊
export const UpdateSubscriptionOrderInvoice = async ({
  shopId,
  id,
  email,
  buyerType,
  buyerName,
  buyerIdentifier,
}) => {
  return await admin2({
    method: 'put',
    url: `/${shopId}/subscriptionOrder/${id}/invoiceInfo`,
    data: {
      email,
      buyerType,
      buyerName,
      buyerIdentifier,
    },
  })
}

// 驗證是否能自動退款
export const GetValidateAutoRefund = async ({
  shopId,
  orderId,
  id,
}) => {
  return await admin2({
    method: 'get',
    url: `/${shopId}/order/${orderId}/subscriptionOrderPayment/${id}/validateAutoRefund`,
  })
}

// 線下開立發票
export const ManualOfflineIssueInvoice = async ({
  shopId,
  orderId,
  id,
  buyerType,
  buyerName,
  buyerIdentifier,
  email,
  amount,
  note,
  invoiceNo,
}) => {
  return await admin2({
    method: 'post',
    url: `/${shopId}/order/${orderId}/subscriptionOrderPayment/${id}/offlineIssueInvoice`,
    data: {
      buyerType,
      buyerName,
      buyerIdentifier,
      email,
      amount,
      note,
      invoiceNo,
    },
  })
}

// 手動作廢發票
export const ManualInvalidInvoice = async ({
  shopId,
  orderId,
  id,
  note,
}) => {
  return await admin2({
    method: 'post',
    url: `/${shopId}/order/${orderId}/subscriptionOrderPayment/${id}/manualInvalidInvoice`,
    data: {
      note,
    }
  })
}

// 手動折讓發票
export const ManualAllowanceInvoice = async ({
  shopId,
  orderId,
  id,
  note,
}) => {
  return await admin2({
    method: 'post',
    url: `/${shopId}/order/${orderId}/subscriptionOrderPayment/${id}/manualAllowanceInvoice`,
    data: {
      note,
    }
  })
}
