<template>
  <main class="member-shop-product-create">
    <div v-loading="loading" class="card-container form-wrapper">
      <SectionTitle :title="pageTitle" hideTitleStick hideBtn />

      <BaseElForm ref="formRef" label-position="top" :model="formData" :rules="formRules">
        <BaseElFormItem label="訂閱方案綁定" prop="planId">
          <BaseElSelect v-model="formData.planId" placeholder="請選擇訂閱方案" @change="planChange">
            <BaseElSelectOption
              v-for="type in subscribePlanList"
              :key="type.id"
              :label="type.name"
              :value="type.id"
            />
          </BaseElSelect>
        </BaseElFormItem>
        <BaseElFormItem label="圖片" prop="image" class="form-relative-label others">
          <img
            v-if="formData.image"
            class="w-[90px] h-[90px]"
            :src="formData.image"
          >
          <p v-else>-</p>
        </BaseElFormItem>
        <BaseElFormItem label="收款金流" prop="paymentCompany">
          <p>{{ formData.paymentCompany || '-' }}</p>
        </BaseElFormItem>
        <BaseElFormItem label="預設售價" prop="price">
          <p>{{ `$ ${formData.price}` || '-' }}</p>
        </BaseElFormItem>
        <BaseElFormItem label="公開顯示" prop="isPublic">
          <BaseElSwitch
            v-model="formData.isPublic"
            inactive-text="關閉"
            active-text="開啟"
          />
        </BaseElFormItem>
        <BaseElFormItem label="排序" prop="order">
          <BaseElInput v-model="formData.order" placeholder="請輸入排序" />
        </BaseElFormItem>
      </BaseElForm>
    </div>

    <PageFixedFooter :confirmLoading="submitting" @cancel="onCancel" @confirm="onConfirm" />
    <!-- <ImageCropper v-if="modal.imageCropper" :image="formData.image" @uploaded="getImage" @close="modal.imageCropper = false" /> -->
  </main>
</template>

<script>
import { defineComponent, reactive, ref, computed, onMounted } from 'vue'
import SectionTitle from '@/components/Title/SectionTitle.vue'
import PageFixedFooter from '@/components/Footer/PageFixedFooter.vue'
import { useRoute, useRouter } from 'vue-router/composables'
import { usePermissions } from '@/use/permissions'
import { noEmptyRules, rangeRules, isDigitRules } from '@/validation'
import formUtils from '@/utils/form'
import store from '@/store'
import { CreateMemberStoreSubscribeProduct, FindMemberStoreSubscribeProduct, UpdateMemberStoreSubscribeProduct } from '@/api/subscribe'
// import UploadButton from '@/components/Button/UploadButton.vue'
import { get, omit, filter, isNull, find } from 'lodash'
import { useFetch } from '@/use/fetch'
import { useSubscribe } from '@/use/useSubscribe'

export default defineComponent({
  name: 'SubscriptionProductEdit',
  components: {
    SectionTitle,
    PageFixedFooter,
    // UploadButton,
    // ImageCropper,
  },
  setup (props) {
    const { getSubscribePlan, subscribePlanList } = useSubscribe()
    const router = useRouter()
    const route = useRoute()
    const { fetchAll } = useFetch()
    const { checkAction } = usePermissions()

    const shopId = computed(() => store.getters.shop)
    const pageTitle = computed(() => {
      if (!productId.value) return '新增訂閱方案商品'
      return '編輯訂閱方案商品'
    })

    const loading = ref(false)
    const productId = computed(() => {
      return route.params.id
    })
    const productData = ref(null)
    const compactPostData = computed(() => {
      const {
        planId,
        image,
        isPublic,
        price,
        order,
      } = formData

      return {
        planId,
        paymentCompany: '永豐儲值金價保',
        imageId: get(image, 'id') || null,
        isPublic,
        price,
        order,
      }
    })
    const formRef = ref(null)
    const submitting = ref(false)
    const formData = reactive({
      planId: null,
      image: null,
      name: null,
      paymentCompany: null,
      isPublic: true,
      price: 0,
      order: 100,
    })
    const formRules = {
      planId: [noEmptyRules('請選擇訂閱方案')],
      isPublic: [noEmptyRules()],
      order: [noEmptyRules(), isDigitRules()],
    }
    const modal = reactive({
      imageCropper: false,
      expWarn: false,
    })
    const planChange = (val) => {
      syncPlanData(val)
    }
    const validateForm = async () => {
      return await formUtils.checkForm(formRef.value)
    }

    const findProduct = async () => {
      const [res, err] = await FindMemberStoreSubscribeProduct({
        shopId: shopId.value,
        id: productId.value,
      })
      if (err) return window.$message.error(err)
      productData.value = res
      formData.planId = get(productData.value, 'subscriptionPlanId')
    }

    const createProduct = async () => {
      const [, err] = await CreateMemberStoreSubscribeProduct({
        shopId: shopId.value,
        ...compactPostData.value,
      })
      if (err) return window.$message.error(err)
      window.$message.success('新增商品成功！')
      router.push({ name: 'MemberShopProductSettings', query: { tab: 'subscription' } })
    }
    const updateProduct = async () => {
      const [, err] = await UpdateMemberStoreSubscribeProduct({
        shopId: shopId.value,
        id: productId.value,
        ...compactPostData.value,
      })
      if (err) return window.$message.error(err)
      window.$message.success('更新商品成功！')
      router.push({ name: 'MemberShopProductSettings', query: { tab: 'subscription' } })
    }
    const syncPlanData = async (id) => {
      const data = find(subscribePlanList.value, { id })
      if (data) {
        formData.price = data.totalAmount
        formData.image = get(data, 'image.imageUrl')
        // TODO: '永豐儲值金價保'
        formData.paymentCompany = '永豐儲值金價保'
      }
    }
    const syncData = async () => {
      const data = productData.value
      console.log('syncData', data)
      formData.planId = data.subscriptionPlanId
      formData.image = data.imageUrl
      formData.isPublic = data.isPublic
      formData.price = data.totalAmount
      formData.order = data.order
      // TODO: '永豐儲值金價保'
      formData.paymentCompany = '永豐儲值金價保'
    }

    const onCancel = () => {
      router.push({ name: 'MemberShopProductSettings', query: { tab: 'subscription' } })
    }

    const onConfirm = async () => {
      submitting.value = true
      if (!await validateForm()) {
        submitting.value = false
        return
      }

      if (!productId.value) {
        await createProduct()
      } else {
        await updateProduct()
      }
      submitting.value = false
    }

    const getImage = (data) => {
      formData.image = data
      modal.imageCropper = false
    }
    const loadImg = (img) => {
      formData.image = img
      modal.imageCropper = true
    }

    onMounted(async () => {
      await getSubscribePlan()
      if (productId.value) {
        loading.value = true
        await findProduct()
        await syncData()
        loading.value = false
      }
    })

    return {
      checkAction,
      formData,
      formRules,
      submitting,
      onCancel,
      onConfirm,
      productId,
      formRef,
      pageTitle,
      modal,
      getImage,
      loadImg,
      productData,
      planChange,
      subscribePlanList,
      loading,
    }
  },
})
</script>

<style scoped lang="postcss">
.form-wrapper {
  @apply px-[32px] py-[24px];
}

.form-relative-label {
  @apply relative;
}

</style>
